exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-built-with-contentful-portfolio-framework-slug-js": () => import("./../../../src/pages/built-with/{contentfulPortfolioFramework.slug}.js" /* webpackChunkName: "component---src-pages-built-with-contentful-portfolio-framework-slug-js" */),
  "component---src-pages-built-with-index-js": () => import("./../../../src/pages/built-with/index.js" /* webpackChunkName: "component---src-pages-built-with-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-contentful-project-slug-js": () => import("./../../../src/pages/projects/{contentfulProject.slug}.js" /* webpackChunkName: "component---src-pages-projects-contentful-project-slug-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */)
}

